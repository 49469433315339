import {jwtDecode} from "jwt-decode";

export const storeTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem('access_token', accessToken);
  localStorage.setItem('refresh_token', refreshToken);

  // Parse the JWT to extract the expiry time and store it
  const expiryTime = getTokenExpiryTime(accessToken);
  if (expiryTime) {
    localStorage.setItem('tokenExpiryTime', expiryTime.toString());
  }
};

export const getStoredToken = (): string => localStorage.getItem('access_token') || '';

export const getStoredRefreshToken = (): string => localStorage.getItem('refresh_token') || '';

// Check if the user is logged in
export const userIsLoggedIn = (): boolean => {
  const token = getStoredToken();
  if (!token) {
    return false;
  }

  const expiryTime = getTokenExpiryTime(token);

  // User is logged in if token exists and is not expired
  return !!token && !!expiryTime && expiryTime > Date.now();
};

// Clear tokens from localStorage
export const clearTokens = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('tokenExpiryTime');
};

// Decode a JWT and extract the expiration time
export const getTokenExpiryTime = (token: string): number | null => {
  try {
    const decoded: { exp: number } = jwtDecode(token); // Ensure proper typing
    return decoded.exp * 1000; // Convert seconds to milliseconds
  } catch (error) {
    console.error('Failed to decode token:', error, token);
    return null;
  }
};