import React, {useEffect, useState} from "react";
import {Box, Button, Popover, Typography} from "@mui/material";
import axiosInstance from "../../api/axiosInstance"
import ProfilePicture from "./ProfilePicture";
import {api} from "../../api/apiService";

export function AccountPopover(props: {
  username: string;
  setIsConfigDialogOpen: (isOpen: boolean) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  fullName: string;
  accountAnchorEl: HTMLElement | null;
  setAccountAnchorEl: (element: HTMLElement | null) => void;
}) {
  const isAccountPopoverOpen = Boolean(props.accountAnchorEl);

  const [profilePic, setProfilePic] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProfilePicture = async () => {
      try {
        const response = await api.get("/api/users/me/profile-picture", {responseType: 'blob'});
        const imageUrl = URL.createObjectURL(response);
        setProfilePic(imageUrl);
      } catch (err) {
        console.error("Error fetching profile picture:", err);
        setError("Failed to load profile picture.");
      }
    };

    fetchProfilePicture();
  }, []);

  return (
    <Popover
      open={isAccountPopoverOpen}
      anchorEl={props.accountAnchorEl}
      onClose={() => props.setAccountAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Box p={2} style={{width: "300px"}}>
        <Typography variant="body2" style={{fontWeight: "bold"}} align="center">
          {props.username}
        </Typography>
        <ProfilePicture />
        {/*<Box display="flex" justifyContent="center" mt={1} mb={1}>*/}
        {/*  /!* Display the profile picture *!/*/}
        {/*  {profilePic ? (*/}
        {/*    <img*/}
        {/*      src={profilePic}*/}
        {/*      alt="Profile"*/}
        {/*      style={{width: "80px", height: "80px", borderRadius: "50%"}}*/}
        {/*    />*/}
        {/*  ) : (*/}
        {/*    <Typography variant="body2" color="textSecondary">*/}
        {/*      {error || "Loading..."}*/}
        {/*    </Typography>*/}
        {/*  )}*/}
        {/*</Box>*/}
        {props.fullName && (
          <Typography variant="body1" align="center" gutterBottom>
            Pozdravljeni, {props.fullName}!
          </Typography>
        )}

        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            props.setAccountAnchorEl(null); // Close the popover
            props.setIsConfigDialogOpen(true); // Open the config dialog
          }}
          style={{textTransform: "none"}} // Prevent uppercase transformation
        >
          Upravljanje računa
        </Button>
        <Button
          variant="text"
          size="small"
          fullWidth
          onClick={() => {
            localStorage.clear();
            props.setIsLoggedIn(false);
            window.location.reload();
          }}
          style={{textTransform: "none", marginTop: "8px"}} // Prevent uppercase and add spacing
        >
          Odjavi se
        </Button>
      </Box>
    </Popover>
  );
}