import './App.css';
import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewsClusters from './NewsClusters';
import {NewsCluster} from "./types";
import {userIsLoggedIn} from "./auth/auth";
import LoginComponent from "./components/LoginComponent";
import {CircularProgress} from "@mui/material";
import {api} from "./api/apiService";

const App = () => {
  const [newsClusters, setNewsClusters] = useState<NewsCluster[]>([]);
  const [loggedInUsername, setLoggedInUsername] = useState<string>(() => {
    return localStorage.getItem('loggedInUsername') || ""; // Default to empty string if not found
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(userIsLoggedIn()); // Track login status in state

  useEffect(() => {
    if (isLoggedIn) {
      // Fetch news clusters
      setIsLoading(true);
      api.get("/api/catholic_news_summary")
        .then((res) => {
          setNewsClusters(res.data);
        })
        .catch((err) => {
          console.error("Error fetching news clusters:", err);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isLoggedIn]);

  const renderProtectedContent = () => (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <NewsClusters
          clusters={newsClusters}
          username={loggedInUsername}
          full_name=""
          setIsLoggedIn={setIsLoggedIn}
        />
      )}
    </div>
  );

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <div className="App">
      {isLoggedIn
        ? renderProtectedContent()
        : <LoginComponent onLogin={handleLogin} setLoggedInUsername={setLoggedInUsername} />}
    </div>
  );
};

export default App;